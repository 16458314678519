// shopping types
export default {

  GET_PARENT_CATEGORIES: 'GET_PARENT_CATEGORIES',
  GET_CATEGORY_BY_SLUG: 'GET_CATEGORY_BY_SLUG',

  GET_PRODUCTS_BY_CATEGORY: 'GET_PRODUCTS_BY_CATEGORY',
  FETCH_PARENT_CATEGORIES: 'FETCH_PARENT_CATEGORIES',
  FETCH_CATEGORY: 'FETCH_CATEGORY',
  FETCH_CATEGORY_RECS: 'FETCH_CATEGORY_RECS',
  SET_NAV_CATEGORY: 'SET_NAV_CATEGORY',
  GET_CATEGORY: 'GET_CATEGORY',
  SET_ACTIVE_CATEGORY: 'SET_ACTIVE_CATEGORY',
  SET_CATEGORY_SCROLL: 'SET_CATEGORY_SCROLL',
};
