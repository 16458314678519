import {
  faPlus,
  faMinus,
  faSpinnerThird,
  faSadCry,
  faClock,
  faMapMarkerAlt,
  faArrowRight,
  faShoppingBasket,
  faTimes,
  faCheck,
  faCircle as openCircle,
  faPhone,
  faEnvelope,
  faTruck,
  faRedo as faRedoLight,
  faPiggyBank,
  faPaperPlane,
  faBoxUsd,
  faBadgeCheck as outlineBadge,
  faCarrot as outlineCarrot,
  faCalendar,
  faCog,
} from '@fortawesome/pro-light-svg-icons';

import {
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faSearch,
  faArrowAltCircleRight,
  faTimesCircle,
  faCheckCircle,
  faToggleOn,
  faToggleOff,
  faLongArrowRight,
  faDotCircle,
  faGift,
  faRedo,
  faPlus as fatPlus,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faBadgeCheck,
  faBadge,
  faEnvelope as solidMail,
  faPhone as solidPhone,
  faCircle,
  faCarrot,
  faSpinner as loader,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faCcAmex,
  faCcVisa,
  faCcMastercard,
  faCcDiscover,
  faCcJcb,
  faCcDinersClub,
} from '@fortawesome/free-brands-svg-icons';

const iconDefinitions = {
  facebook: faFacebookF,
  instagram: faInstagram,
  twitter: faTwitter,
  phone: faPhone,
  mail: faEnvelope,
  right: faAngleRight,
  down: faAngleDown,
  up: faAngleUp,
  search: faSearch,
  spinner: faSpinnerThird,
  circleRight: faArrowAltCircleRight,
  plus: faPlus,
  minus: faMinus,
  cry: faSadCry,
  clock: faClock,
  location: faMapMarkerAlt,
  longArrowRight: faLongArrowRight,
  angleRight: faAngleRight,
  arrowRight: faArrowRight,
  basket: faShoppingBasket,
  close: faTimes,
  checkCircle: faCheckCircle,
  circleCheck: faCheckCircle,
  badgeCheck: faBadgeCheck,
  badge: faBadge,
  check: faCheck,
  error: faTimesCircle,
  success: faBadgeCheck,
  toggleOn: faToggleOn,
  toggleOff: faToggleOff,
  circleOn: faDotCircle,
  circleOff: openCircle,
  circle: faCircle,
  solidMail,
  solidPhone,
  gift: faGift,
  recur: faRedo,
  carrot: faCarrot,
  truck: faTruck,
  redo: faRedoLight,
  piggyBank: faPiggyBank,
  paperPlane: faPaperPlane,
  boxDollar: faBoxUsd,
  outlineBadge,
  outlineCarrot,
  calendar: faCalendar,
  fatPlus,
  gear: faCog,
  loader,

  // we named them this one to transform stripe card types
  AmericanExpress: faCcAmex,
  Visa: faCcVisa,
  MasterCard: faCcMastercard,
  Discover: faCcDiscover,
  JCB: faCcJcb,
  DinersClub: faCcDinersClub,

};

/**
 * Given an icon name (i.e. 'phone')
 *   - Retrieves the icon definition from above
 */
export default icon => (iconDefinitions[icon]);
