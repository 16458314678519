import React from 'react';
import CartTitle from './CartTitle';
// import CheckoutBanner from './CheckoutBanner';
import './cartHeader.scss';

export default () => (
  <div>
    <CartTitle />
  </div>
);
