// account types
export default {
  GET_ACCOUNT: 'GET_ACCOUNT',
  CLEAR_ACCOUNT: 'CLEAR_ACCOUNT',
  UPDATE_ACCOUNT_CONTACT: 'UPDATE_ACCOUNT_CONTACT',
  UPDATE_ACCOUNT_ADDRESS: 'UPDATE_ACCOUNT_ADDRESS',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  GET_PAYMENT_METHODS: 'GET_PAYMENT_METHODS',
  CHECK_WALKTHROUGH: 'CHECK_WALKTHROUGH',
};
