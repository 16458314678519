/* eslint-disable */
import React from "react";

export default props => (
  <svg version="1.0"
   width="700" height="236" viewBox="0 0 700 236"
   {...props}>
   <g transform="translate(0.000000,236.000000) scale(0.100000,-0.100000)" stroke="none">
   <path d="M6405 2162 c-2 -2 8 -28 21 -58 l24 -55 0 -437 0 -437 -25 -53 c-14
   -30 -25 -56 -25 -58 0 -2 18 -4 40 -4 29 0 40 -4 40 -15 0 -13 30 -15 206 -15
   l205 0 -7 23 c-3 12 -15 39 -25 59 -18 35 -19 67 -19 462 l0 425 25 57 c14 31
   25 61 25 66 0 6 -19 8 -43 7 -37 -3 -43 -1 -39 13 4 16 -13 17 -198 20 -111 2
   -203 2 -205 0z m408 -1026 c31 -67 31 -66 17 -66 -6 0 -24 28 -40 63 l-30 62
   0 417 c0 229 3 425 8 435 4 10 9 -178 12 -417 l5 -435 28 -59z"></path>
   <path d="M126 2103 c14 -32 73 -251 131 -486 l105 -429 -26 -58 c-14 -31 -26
   -60 -26 -64 0 -3 18 -6 40 -6 29 0 40 -4 40 -15 0 -13 36 -15 260 -15 143 0
   260 2 260 5 0 2 -12 29 -27 59 l-26 54 107 433 c58 239 118 458 131 487 14 30
   25 56 25 59 0 3 -22 3 -49 1 -44 -3 -48 -1 -40 15 9 16 -3 17 -196 17 -125 0
   -205 -4 -205 -10 0 -5 9 -31 19 -58 l20 -49 -44 -272 c-24 -150 -46 -275 -48
   -278 -6 -6 -8 4 -58 295 -31 186 -38 249 -31 270 l10 27 1 -28 c1 -36 70 -450
   77 -456 2 -2 4 0 4 5 1 5 5 29 10 54 6 32 3 87 -12 193 l-20 149 21 55 c12 30
   21 59 21 64 0 14 -78 11 -84 -3 -3 -7 -4 -1 -1 15 l5 27 -210 0 -210 0 26 -57z
   m859 -98 c-7 -28 -14 -43 -14 -35 -1 19 19 93 24 88 2 -2 -2 -26 -10 -53z
   m-18 -77 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-91 -377 l-84
   -343 29 -64 c33 -71 33 -74 18 -74 -5 0 -22 26 -36 58 -29 67 -37 16 74 471
   42 173 78 311 80 305 2 -5 -35 -164 -81 -353z"></path>
   <path d="M3458 2081 c-73 -24 -116 -51 -165 -104 -89 -97 -127 -206 -127 -367
   0 -119 13 -187 56 -277 51 -109 138 -178 303 -240 90 -34 326 -10 339 35 11
   37 48 274 43 278 -3 3 -18 -1 -34 -10 -28 -14 -31 -21 -47 -118 -9 -56 -19
   -104 -21 -107 -8 -7 -6 15 15 146 11 69 19 127 17 129 -2 3 -37 -10 -78 -27
   -143 -59 -228 -49 -271 34 -19 36 -22 57 -22 152 0 61 5 120 10 132 6 15 9
   -19 9 -107 0 -114 2 -132 21 -156 33 -44 44 -42 32 8 -13 56 -4 183 15 230 32
   77 144 104 219 53 46 -31 60 -32 54 -2 -3 12 -8 49 -11 82 -11 126 -18 181
   -26 196 -25 48 -231 73 -331 40z"></path>
   <path d="M1208 2084 c-4 -3 2 -24 13 -47 10 -23 19 -53 19 -66 0 -37 -176
   -741 -200 -795 -11 -26 -20 -50 -20 -52 0 -2 18 -4 40 -4 29 0 40 -4 40 -15 0
   -13 27 -15 185 -15 102 0 185 4 185 9 0 5 -7 25 -15 44 -26 64 -21 77 29 77
   37 0 46 4 51 21 5 15 9 18 15 9 4 -7 -3 -37 -15 -69 l-23 -56 39 -3 c26 -2 39
   -8 39 -18 0 -12 31 -14 190 -14 162 0 190 2 190 15 0 10 10 15 30 15 20 0 30
   -5 30 -15 0 -13 41 -15 309 -15 l308 0 7 46 c10 74 7 244 -4 244 -5 0 -19 -9
   -30 -20 -17 -17 -20 -33 -20 -125 0 -58 -4 -105 -9 -105 -4 0 -6 65 -3 145 3
   80 2 145 -2 145 -4 0 -29 -9 -55 -20 -39 -17 -64 -20 -142 -18 l-94 3 -3 298
   c-1 164 1 304 5 312 5 7 7 -82 5 -198 -2 -117 -1 -249 3 -294 6 -79 8 -83 31
   -83 l24 0 0 259 c0 237 2 263 20 306 27 64 26 68 -20 63 -37 -3 -39 -2 -34 19
   l6 23 -191 0 c-109 0 -191 -4 -191 -9 0 -5 9 -30 20 -56 19 -44 20 -69 20
   -423 -1 -345 -2 -380 -19 -417 l-19 -40 -10 27 c-6 15 -59 192 -118 394 l-107
   368 17 45 c25 67 25 66 -25 66 -42 0 -45 2 -41 23 l4 22 -229 0 c-126 0 -232
   -3 -235 -6z m557 -494 c57 -196 113 -377 124 -404 24 -55 24 -56 11 -56 -19 0
   -46 80 -158 463 -72 243 -108 383 -104 399 5 21 6 20 15 -12 5 -19 55 -195
   112 -390z m-296 40 c13 -58 25 -115 28 -127 5 -22 2 -23 -52 -23 -49 0 -56 2
   -51 18 3 9 13 70 22 135 10 65 20 114 24 110 4 -4 17 -55 29 -113z m-70 -448
   c15 -37 17 -52 8 -52 -15 0 -60 107 -51 122 8 13 15 2 43 -70z"></path>
   <path d="M1438 1675 c-3 -5 -9 -32 -12 -60 -4 -27 -10 -60 -13 -72 -4 -19 -1
   -23 20 -23 20 0 27 6 31 28 6 30 -16 143 -26 127z"></path>
   <path d="M2690 2076 c0 -8 9 -35 20 -61 19 -44 20 -69 20 -415 0 -345 -1 -371
   -20 -411 -27 -61 -25 -67 18 -71 23 -2 36 -8 35 -15 -2 -10 40 -13 187 -13
   109 0 190 4 190 9 0 5 -9 30 -20 56 -19 44 -20 69 -20 411 0 362 0 364 24 418
   13 30 22 57 18 60 -10 11 -68 6 -81 -6 -10 -11 -10 -8 -2 13 5 15 7 30 4 33
   -4 3 -89 6 -190 6 -151 0 -183 -2 -183 -14z m378 -884 c25 -61 26 -62 11 -62
   -5 0 -21 25 -35 55 l-24 56 0 358 c0 198 3 368 8 378 4 10 9 -151 12 -357 5
   -361 6 -377 28 -428z"></path>
   <path d="M3947 2084 c-3 -3 5 -31 19 -61 l24 -55 0 -367 c0 -366 0 -366 -24
   -418 -13 -28 -22 -54 -18 -57 3 -3 27 -6 54 -6 36 0 48 -4 48 -15 0 -13 40
   -15 303 -15 l304 0 6 98 c9 135 9 192 -2 192 -5 0 -21 -4 -35 -10 -24 -9 -26
   -15 -27 -72 -1 -35 -5 -88 -9 -118 -6 -37 -7 -8 -4 93 3 81 2 147 0 147 -3 0
   -28 -9 -56 -20 -38 -15 -74 -20 -145 -20 l-95 0 2 53 c1 35 2 42 5 20 4 -32 6
   -33 48 -33 29 0 45 5 49 15 4 11 22 15 71 15 l65 0 0 145 c0 86 -4 145 -10
   145 -5 0 -32 -9 -60 -20 -31 -12 -74 -20 -110 -20 l-60 0 2 68 c1 56 2 59 5
   20 l4 -48 45 0 c40 0 44 2 44 25 0 25 1 25 83 25 l82 0 -6 132 c-4 73 -7 141
   -8 151 -1 16 -22 17 -294 17 -162 0 -296 -3 -300 -6z"></path>
   <path d="M4708 2085 c-3 -3 5 -36 18 -73 23 -65 24 -75 24 -415 l0 -347 -25
   -62 c-14 -34 -25 -63 -25 -65 0 -2 18 -3 40 -3 29 0 40 -4 40 -15 0 -13 27
   -15 180 -15 102 0 180 4 180 9 0 5 -9 30 -20 56 -16 37 -20 69 -20 156 0 60 3
   109 8 109 4 0 20 -20 36 -45 15 -25 62 -93 102 -150 59 -83 80 -105 98 -105
   13 0 29 -7 36 -15 9 -11 35 -15 101 -15 l89 0 0 427 c0 372 2 433 16 470 9 24
   19 43 23 43 3 0 4 -55 2 -121 -4 -133 -6 -128 52 -104 14 6 17 2 17 -24 0 -17
   2 -31 5 -31 22 0 114 52 118 67 3 10 5 -118 6 -284 1 -280 0 -306 -19 -353
   -11 -28 -20 -53 -20 -55 0 -3 18 -5 40 -5 29 0 40 -4 40 -15 0 -13 28 -15 190
   -15 105 0 190 2 190 5 0 2 -9 27 -20 55 -18 47 -20 74 -20 345 l0 295 65 0 65
   0 -6 138 c-3 75 -7 143 -10 150 -3 9 -80 12 -344 12 -333 0 -340 0 -340 -20 0
   -17 -7 -20 -43 -20 -24 0 -49 -6 -56 -12 -10 -11 -10 -8 -2 13 5 15 7 30 4 33
   -8 8 -348 8 -355 0 -4 -3 5 -30 18 -60 22 -50 24 -68 24 -200 0 -92 -4 -144
   -10 -142 -5 2 -68 93 -138 203 l-129 200 -109 3 c-61 1 -113 0 -116 -3z m789
   -743 c-1 -119 -2 -21 -2 218 0 239 1 337 2 217 2 -119 2 -315 0 -435z m633
   193 c0 -279 1 -287 24 -345 18 -43 21 -60 12 -60 -7 0 -23 25 -35 56 -20 52
   -21 74 -21 345 0 186 4 289 10 289 6 0 10 -102 10 -285z m-1090 -140 c0 -121
   3 -142 25 -197 29 -72 28 -68 13 -68 -7 0 -23 25 -35 55 -20 49 -23 73 -23
   200 0 90 4 145 10 145 6 0 10 -52 10 -135z"></path>
   <path d="M4567 2043 c-3 -5 -2 -63 1 -131 5 -119 5 -123 32 -142 15 -11 29
   -18 31 -15 5 4 -10 264 -16 283 -5 13 -41 17 -48 5z"></path>
   <path d="M5000 2040 c0 -5 42 -74 93 -152 90 -140 92 -142 95 -106 3 31 -8 53
   -72 152 -55 85 -81 116 -96 116 -11 0 -20 -4 -20 -10z"></path>
   <path d="M6327 2043 c-2 -4 -2 -66 1 -136 4 -122 6 -129 29 -144 13 -9 25 -14
   28 -12 2 3 1 70 -3 149 -7 138 -8 145 -29 148 -11 2 -23 0 -26 -5z"></path>
   <path d="M3814 2003 c3 -15 10 -71 16 -123 16 -137 19 -147 47 -165 13 -9 27
   -15 29 -13 7 7 -34 300 -43 308 -4 4 -19 11 -32 15 -21 6 -23 5 -17 -22z"></path>
   <path d="M4567 1687 c-15 -11 -17 -28 -15 -123 3 -111 3 -111 26 -103 21 8 22
   13 22 124 0 63 -4 115 -8 115 -5 0 -16 -6 -25 -13z"></path>
   <path d="M1378 804 c-4 -3 0 -21 8 -39 21 -50 20 -531 0 -580 l-15 -35 95 0
   c51 0 94 2 94 4 0 2 -7 20 -15 40 -12 27 -15 65 -13 139 l3 102 75 5 c50 4 88
   13 110 26 132 77 108 293 -35 333 -41 11 -297 16 -307 5z m271 -130 c24 -30
   26 -52 9 -88 -13 -29 -67 -49 -105 -40 -21 6 -23 11 -23 73 0 37 3 71 7 74 3
   4 26 7 49 7 34 0 47 -5 63 -26z"></path>
   <path d="M3760 806 c0 -2 7 -20 15 -39 22 -52 22 -531 1 -582 l-15 -35 95 0
   c51 0 94 2 94 5 0 2 -7 22 -15 43 -12 28 -15 67 -13 148 l3 109 47 3 c25 2 62
   -4 81 -12 20 -8 38 -12 41 -8 3 3 6 39 6 80 0 73 0 74 -22 67 -13 -5 -52 -9
   -88 -9 l-65 -1 0 60 0 60 49 3 c27 2 65 -3 87 -12 21 -9 40 -12 43 -8 2 4 1
   34 -3 67 l-6 60 -167 3 c-93 1 -168 1 -168 -2z"></path>
   <path d="M910 789 c-73 -12 -182 -65 -272 -132 -79 -60 -107 -72 -139 -62 -16
   5 -19 14 -17 38 3 28 7 32 31 32 53 1 61 43 17 87 -26 25 -36 29 -62 24 -42
   -8 -76 -41 -88 -86 -26 -95 67 -195 163 -175 44 10 90 34 204 109 189 122 278
   133 369 45 35 -34 68 -33 79 2 10 31 -18 64 -77 92 -62 30 -132 38 -208 26z"></path>
   <path d="M5939 790 c-60 -10 -100 -32 -133 -70 -21 -26 -24 -35 -15 -51 18
   -34 37 -33 79 4 95 87 190 68 418 -81 86 -56 143 -82 182 -82 34 1 101 41 120
   72 46 75 5 178 -78 194 -26 5 -36 1 -62 -24 -16 -17 -30 -37 -30 -45 0 -21 37
   -50 55 -43 21 8 36 -32 23 -58 -17 -32 -71 -14 -161 54 -95 72 -133 93 -207
   113 -75 21 -136 26 -191 17z"></path>
   <path d="M2415 761 c-128 -33 -177 -172 -93 -267 17 -19 64 -53 105 -76 81
   -44 100 -67 83 -98 -21 -39 -86 -38 -168 1 -24 12 -45 19 -47 17 -9 -8 16
   -133 28 -140 27 -18 95 -30 147 -26 106 8 162 55 176 146 14 92 -23 144 -156
   222 -65 38 -85 69 -61 93 17 17 94 11 144 -11 l47 -21 0 33 c0 69 -11 98 -45
   112 -42 17 -122 25 -160 15z"></path>
   <path d="M1920 756 c0 -3 4 -16 10 -31 8 -21 -1 -71 -45 -248 -31 -122 -62
   -239 -70 -259 l-15 -38 86 0 c84 0 85 0 78 23 -18 55 -13 62 50 65 66 3 80 -7
   65 -45 -5 -14 -9 -29 -9 -34 0 -5 38 -9 85 -9 47 0 85 2 85 4 0 2 -6 19 -14
   37 -16 39 -127 444 -128 468 0 9 3 28 7 44 l7 27 -96 0 c-53 0 -96 -2 -96 -4z
   m116 -268 c7 -35 13 -70 14 -79 0 -20 -26 -32 -52 -23 -17 5 -19 12 -13 42 16
   88 27 133 32 128 3 -3 11 -33 19 -68z"></path>
   <path d="M2710 718 c0 -24 -3 -54 -7 -67 -5 -21 -4 -23 13 -17 10 5 38 11 62
   13 l42 6 0 -204 c-1 -158 -4 -210 -15 -229 -8 -14 -14 -28 -15 -32 0 -5 41 -8
   91 -8 83 0 90 1 83 18 -5 9 -10 115 -12 236 l-3 218 33 -5 c18 -3 44 -8 57
   -13 23 -7 24 -5 18 26 -4 18 -7 48 -7 66 l0 34 -170 0 -170 0 0 -42z"></path>
   <path d="M3190 756 c0 -1 4 -16 10 -32 8 -24 -2 -73 -54 -269 -34 -132 -66
   -248 -71 -257 -6 -16 1 -18 80 -18 85 0 86 0 79 23 -19 57 -14 62 56 62 70 0
   70 0 55 -58 l-7 -27 79 0 c86 0 97 4 82 29 -5 9 -38 121 -73 250 -49 179 -62
   240 -55 258 5 13 9 29 9 34 0 5 -43 9 -95 9 -52 0 -95 -2 -95 -4z m114 -269
   c22 -95 22 -97 -20 -97 -32 0 -36 2 -31 23 3 12 11 50 18 85 6 34 13 61 15 60
   1 -2 10 -34 18 -71z"></path>
   <path d="M4256 713 c4 -37 -9 -99 -54 -273 -32 -124 -62 -233 -66 -242 -7 -16
   0 -18 79 -18 79 0 86 2 80 18 -3 9 -9 29 -12 45 l-5 27 67 0 67 0 -6 -45 -7
   -45 82 0 c73 0 81 2 76 18 -2 9 -24 87 -47 172 -23 85 -53 192 -65 237 -18 64
   -22 90 -14 115 5 18 9 34 9 35 0 2 -43 3 -95 3 l-94 0 5 -47z m123 -304 c1
   -20 -25 -32 -51 -23 -21 6 -21 13 5 129 l11 50 17 -70 c10 -38 18 -77 18 -86z"></path>
   <path d="M4636 733 c12 -36 12 -490 0 -526 l-8 -27 87 0 c79 0 86 2 80 18 -10
   22 -19 142 -11 142 3 0 17 -23 31 -51 22 -42 25 -56 16 -80 l-10 -29 100 0
   c54 0 99 2 99 4 0 3 -23 41 -51 86 l-50 81 35 39 c56 62 72 162 41 251 -34 95
   -84 118 -249 119 l-118 0 8 -27z m213 -107 c59 -63 20 -186 -60 -186 -5 0 -9
   47 -9 105 0 103 0 105 23 105 13 0 34 -11 46 -24z"></path>
   <path d="M5127 754 c-3 -3 0 -19 7 -35 10 -23 8 -72 -9 -256 -12 -126 -27
   -238 -33 -250 -7 -12 -12 -24 -12 -27 0 -3 38 -6 85 -6 47 0 85 2 85 4 0 3 -5
   17 -11 33 -7 18 -8 67 -4 143 8 129 9 129 66 18 25 -49 33 -58 57 -58 24 0 32
   10 67 78 l40 77 6 -45 c12 -78 12 -196 0 -218 -6 -11 -11 -23 -11 -26 0 -3 38
   -6 85 -6 88 0 94 4 74 41 -16 30 -59 469 -48 497 15 38 11 42 -39 42 l-48 0
   -62 -132 c-34 -73 -64 -132 -67 -131 -4 1 -34 61 -68 132 l-62 130 -46 1 c-25
   0 -48 -3 -52 -6z"></path>
   <path d="M875 616 c-27 -12 -88 -48 -135 -78 -105 -69 -195 -114 -242 -123
   -67 -13 -134 39 -153 117 -10 39 -65 48 -79 12 -7 -19 17 -78 51 -122 35 -46
   114 -82 180 -82 71 0 136 26 238 96 157 109 186 124 231 124 50 0 88 -28 131
   -95 30 -48 60 -58 84 -29 11 13 10 21 -6 51 -27 49 -92 121 -126 138 -42 22
   -116 18 -174 -9z"></path>
   <path d="M5924 621 c-36 -22 -92 -85 -118 -135 -15 -29 -17 -38 -6 -51 25 -30
   56 -18 89 34 49 75 65 86 130 86 54 0 60 -3 196 -95 77 -53 159 -101 181 -108
   129 -38 261 19 309 135 20 47 19 60 -5 73 -29 15 -44 3 -77 -60 -65 -126 -151
   -115 -403 50 -52 35 -109 69 -127 77 -47 19 -132 17 -169 -6z"></path>
   <path d="M873 435 c-17 -7 -66 -36 -107 -65 -208 -140 -313 -162 -403 -85 -50
   43 -80 46 -89 8 -6 -23 -1 -32 34 -64 112 -99 314 -73 497 65 88 66 106 76
   144 76 35 0 45 -12 37 -46 -5 -17 -13 -24 -30 -24 -25 0 -56 -23 -56 -41 0 -6
   13 -24 29 -40 66 -66 161 -8 161 98 0 101 -114 163 -217 118z"></path>
   <path d="M5963 434 c-66 -33 -90 -110 -57 -180 40 -83 123 -86 168 -7 13 23
   -13 53 -44 53 -19 0 -31 6 -35 19 -10 34 5 51 44 51 30 0 53 -11 112 -56 206
   -155 401 -188 518 -87 46 38 54 75 21 93 -16 9 -26 5 -58 -24 -95 -86 -184
   -74 -382 55 -58 37 -122 75 -142 83 -48 20 -104 20 -145 0z"></path>
   </g>
  </svg>
);
